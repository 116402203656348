import React from "react"
import { Helmet } from "react-helmet"
import "./events.css"
import "../components/partner.css"
import 'bootstrap/dist/css/bootstrap.min.css'

import EmmaHead from "../images/gatsby-icon.png"
import event_img from '../images/events-img/lives-2020.png'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import EventBanner from "../components/event-banner.js"

const EventsPage = () => (
  <div className="index">
    <Helmet>
      <title>Lives e Mesas Redondas 2020</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "Lives e Mesas Redondas 2020"
        date = "Junho-Outubro/2020"
        description = "'Live: Lidando com a autocobrança', com a participação da psicanalista Cláudia Serathiuk, em Junho/2020. 'Mesa Redonda: Quarentena sim, violência não', com a participação de Viviana Santiago, gerente de gênero e incidência política e Marina Ganzarolli, presidente da Comissão Especial da Diversidade Sexual Seção São Paulo da Ordem dos Advogados do Brasil, em Junho/2020. 'Live: O Papel da Mulher na Pesquisa e Educação Brasileira', com a participação da Professora Doutora Sheyla Marques, Doutora em Ciência e Engenharia dos Materiais pela Universidade Federal do Rio Grande do Norte, em Agosto/2020. 'Live: Mulheres na Indústria 4.0', com a participação de Isabela Quadros, engenheira mecânica pelo Instituto Mauá de Tecnologia e Swansea University, e Engenheira de Aplicação responsável pelos treinamentos GOM na Vtech, em Agosto/2020. 'Mesa Redonda: Mulheres no Mercado Financeiro', com a participaçaõ de Betina Roxo (Estrategista chefe na Rico e Sócia da XP Inc.), Camilla Dolle e Rachel de Sá (Responsáveis pela análise de renda fixa da XP Investimentos), em Setembro/2020. 'Mesa Redonda: A Atuação Feminina na Educação Tecnológica', com a participação de Natália Valentin e Larissa Carlotti, que trabalham atualmente na Mastertech ensinando outras mulheres a transformarem suas vidas através da programação, em Setembro/2020. 'Mesa Redonda: Mulheres na Astronomia Brasileira', com a participação de Júlia Santos (formada em Química pela UFRJ e mestranda em Astronomia pela USP) e Yanna Martins (bacharel, mestranda e doutoranda em Astronomia no Observatório do Valongo - UFRJ), em Outubro/2020. 'Mesa Redonda: Mulheres Negras em STEM', com a participação de Carla Vieira (bacharel em SI e mestranda em IA pela USP, engenheira de software, Google Developer e co-organizadora da perifaCode), e Karol Pessoa (engenheira civil pela PUCSP), em Outubro/2020."
      />
      {/* <div className="partner-container">
        <div className="partner-title">PARCERIAS</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={InvistaLogo} alt="" className="partner-logo"/>
                <p className="partner-name">Invista como uma Garota</p>
            </div>
        </div>
      </div> */}
      <MyFooter />
  </div>
)

export default EventsPage
